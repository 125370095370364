.homeOptions {
  padding: 20px;

  .brandLogo {
    width: 130px;
    height: 130px;
    position: relative;
    margin-bottom: 25px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    overflow: hidden;

    img {
      object-fit: contain;
    }
  }

  .brandName {
    color: #404040;
    font-size: 24px;
    line-height: 34px;
    font-weight: 600;
    margin-bottom: 10px;
    text-align: center;
  }

  .brandDescription {
    color: rgba(0, 0, 0, 0.45);
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
    margin-top: 35px;

    .item {
      padding: 15px 20px 15px 15px;
      border-radius: 15px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;

      @media screen and (min-width: 992px) {
        align-items: center;
      }

      &:hover {
        background: #f7f7f7;
        cursor: pointer;
      }

      .icon {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (min-width: 992px) {
          width: 55px;
          height: 55px;
          background: rgba(217, 217, 217, 0.33);
          border-radius: 20px;
        }
      }

      .chevron {
        position: absolute;
        right: 20px;

        @media screen and (min-width: 992px) {
          top: 30px;
        }
      }

      .name {
        font-size: 14px;
        line-height: 20px;
        margin-top: 15px;
        color: #666666;
        font-weight: 600;
      }
    }
  }
}
