.home {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  
    .fullscreenIframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
    }
  }
  